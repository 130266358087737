/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

// Delete this file after migrating to mui version 5

import React from "react";
import PropTypes from "prop-types";

import store from "@untyped-store";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import theme from "@untyped-styles/theme";

function RootComponentv4({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Provider store={store}>{children}</Provider>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}

RootComponentv4.propTypes = {
  children: PropTypes.node,
};

export default RootComponentv4;
