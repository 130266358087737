/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import EventAttendeesPage from "./eventAttendeesPage";

export default EventAttendeesPage;
