/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";

import CSSGrid from "./cssGrid";
import RootComponent from "@untyped-shared/RootComponentv4";

function EventAttendeesPage({ eventId, jwt, editable, overwriteState, ping }) {
  return (
    <RootComponent>
      <CSSGrid
        eventId={eventId}
        jwt={jwt}
        editable={editable}
        ping={ping}
        overwriteState={overwriteState}
      />
    </RootComponent>
  );
}

EventAttendeesPage.propTypes = {
  eventId: PropTypes.string,
  jwt: PropTypes.string,
  editable: PropTypes.bool,
  overwriteState: PropTypes.string,
  ping: PropTypes.bool,
};

export default EventAttendeesPage;
