/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

/* Global I18n */

import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import SpinnerComp from "@untyped-conference/containers/spinner";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";
import {
  getEvent,
  selectActiveEvent,
  selectLoading,
  upsertEvent,
} from "@untyped-store/slices/eventsSlice";

import {
  selectShowQA,
  selectActiveEventState,
  selectShowPubQA,
} from "@untyped-store/selectors/activeEvent";

import { subscribeToEventState } from "@untyped-store/middleware/websocketMiddleware";

import { selectActiveSurveyName } from "@untyped-store/slices/surveysSlice";
import {
  setShowPreview,
  setIsFocusViewTemplate,
} from "@untyped-store/slices/metaSlice";
import { setJWT } from "@untyped-store/slices/jwtSlice";

import {
  EventTitle,
  EventDescription,
  EventVideo,
  EventSlate,
  EventQuestions,
  EventLogo,
  EventSurvey,
  EventQuestionRoomsList,
  EventCountdownBar,
} from "@untyped-shared/eventFeatures";
import { selectSelectedRoom } from "@untyped-store/slices/questionRoomsSlice";
import useEvent from "@untyped-hooks/useEvent";

const useStyles = makeStyles((theme) => ({
  wrapperVideoOnly: {
    display: "grid",
    gridTemplateColumns: "repeat(6,1fr)",
    gridTemplateRows: "1fr auto auto",
    gridGap: "15px",
    [theme.breakpoints.down("md")]: {
      gridTemplateAreas: `
        'title title title title title title'
        'countdown countdown countdown countdown countdown countdown'
        'video video video video video video'
        'question question question question question question'
        'description description description description description description'
      `,
    },
    [theme.breakpoints.between(768, 960)]: {
      margin: "0 15px",
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateAreas: `
        'e title title title title f'
        'e countdown countdown countdown countdown f'
        'e video video video video f'
        'e question question question question f'
        'e description description description description f'
      `,
    },
  },
  wrapperFocus: {
    display: "grid",
    gridTemplateRows: "auto auto auto auto 1fr",
    gridGap: "15px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "auto",
      gridTemplateAreas: `
        'logo'
        'video'
        'title'
        'description'
        'questionRooms'
        'focus'
      `,
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(4,1fr)",
      gridTemplateAreas: `
        'logo focus focus focus'
        'video focus focus focus'
        'title focus focus focus'
        'description focus focus focus'
        'questionRooms focus focus focus'
      `,
    },
    margin: "15px",
  },
  title: {
    gridArea: "title",
  },
  logo: {
    gridArea: "logo",
  },
  video: {
    gridArea: "video",
  },
  question: {
    gridArea: "question",
  },
  countdown: {
    gridArea: "countdown",
  },
  videoWithoutMargin: {
    gridArea: "video",
    marginTop: "-15px",
  },
  description: {
    gridArea: "description",
  },
  questionRooms: {
    gridArea: "questionRooms",
  },
  focus: {
    gridArea: "focus",
    height: "80vh",
    minHeight: "700px",
  },
  interactivePart: {
    backgroundColor: "lightgrey",
  },
  surveyCardContainer: {
    height: "75vh",
    overflowY: "scroll",
  },
  qaCardContainer: {
    height: "100%",
  },
  qaCardHeader: {
    backgroundColor: "#2C3039",
    color: "white",
    fontSize: "large",
    fontWeight: "bold",
  },
}));

function CSSGrid({ eventId, jwt, editable, overwriteState, ping }) {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const event = useSelector(selectActiveEvent);
  const showQA = useSelector(selectShowQA);
  const showPubQA = useSelector(selectShowPubQA);
  const selectedQuestionRoom = useSelector(selectSelectedRoom);
  const surveyName = useSelector(selectActiveSurveyName);
  const countdownAllowed = event && event.countdownAllowed;
  const questionRoomHeadline =
    selectedQuestionRoom &&
    (selectedQuestionRoom.headline || I18n.t("questions.title"));
  const state = useSelector(selectActiveEventState);
  dispatch(setIsFocusViewTemplate(true));

  useEvent({
    eventId,
    jwt,
    overwriteState,
  });

  useEffect(() => {
    dispatch(setJWT(jwt));
    dispatch(getEvent({ eventId }));
    if (overwriteState) {
      dispatch(upsertEvent({ id: eventId, overwrittenState: overwriteState }));
      dispatch(setShowPreview(true));
    }
    // for more infos why this is this way either take a look at ticket: SSY-2846
    // or the comments in the "useEvent" hook
    const subObj = dispatch(subscribeToEventState(eventId));
    return () => {
      subObj.then(({ subscription }) => {
        subscription.cancel();
      });
    };
  }, []);

  const classes = useStyles();

  let videoComp;
  let focusTitle;
  let focusComp;

  const onEndCallback = useCallback(() => {
    dispatch(getEvent({ eventId: event.id }));
  }, [eventId]);

  if (event) {
    switch (state) {
      case "live":
        videoComp = (
          <EventVideo
            forStream={event.localizedStreamId}
            onEnded={onEndCallback}
            statisticKey={event.statisticKey}
            ping={ping}
          />
        );
        break;
      case "on_demand":
        // TODO implement
        videoComp = <EventSlate />;
        break;
      default:
        videoComp = <EventSlate />;
    }

    switch (true) {
      case !!event.activeSurvey:
        focusComp = <EventSurvey forId={event.activeSurvey} />;
        focusTitle = surveyName;
        break;
      case showPubQA:
        focusComp = <EventQuestions horizontal forEventId={event.id} />;
        focusTitle = questionRoomHeadline;
        break;
      default:
      // code block
    }
  }

  const layout = focusComp ? classes.wrapperFocus : classes.wrapperVideoOnly;
  const videoClass =
    event && event.logo ? classes.video : classes.videoWithoutMargin;

  return (
    <>
      {loading && <SpinnerComp />}
      {event && (
        <div className={layout}>
          <div className={classes.title}>
            <EventTitle withLogo={!focusComp} />
          </div>
          {countdownAllowed && !focusComp && (
            <div className={classes.countdown}>
              <EventCountdownBar eventId={eventId} videoOnly />
            </div>
          )}
          <div className={videoClass}>{videoComp}</div>
          <div className={classes.description}>
            <EventDescription editable={editable} />
          </div>
          {!!focusComp && (
            <>
              {showQA && (
                <div className={classes.questionRooms}>
                  <EventQuestionRoomsList />
                </div>
              )}
              {event.logo && (
                <div className={classes.logo}>
                  <EventLogo logo={event.logo} />
                </div>
              )}
              <div className={classes.focus}>
                {countdownAllowed && <EventCountdownBar eventId={eventId} />}
                <Card elevation={1} className={classes.qaCardContainer}>
                  <CardHeader
                    title={focusTitle}
                    className={classes.qaCardHeader}
                  />
                  {event.activeSurvey ? (
                    <CardContent className={classes.surveyCardContainer}>
                      {focusComp}
                    </CardContent>
                  ) : (
                    <CardContent style={{ height: "100%" }}>
                      {focusComp}
                    </CardContent>
                  )}
                </Card>
              </div>
            </>
          )}
          {!focusComp && showQA && (
            <div className={classes.question}>
              <EventQuestions horizontal={false} forEventId={event.id} />
            </div>
          )}
        </div>
      )}
    </>
  );
}

CSSGrid.propTypes = {
  eventId: PropTypes.string,
  jwt: PropTypes.string,
  editable: PropTypes.bool,
  overwriteState: PropTypes.string,
  ping: PropTypes.bool,
};

export default CSSGrid;
