/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";

import RootComponent from "@untyped-shared/RootComponent";
import EventContainer from "./EventContainer";
import { EventDescription } from "@untyped-shared/eventFeatures";

const EventDescriptionLegacy = ({ eventId, isSuperAdmin }) => {
  return (
    <RootComponent>
      <EventContainer eventId={eventId}>
        <EventDescription editable={true} isSuperAdmin={isSuperAdmin} />
      </EventContainer>
    </RootComponent>
  );
};

EventDescriptionLegacy.propTypes = {
  eventId: PropTypes.any,
  isSuperAdmin: PropTypes.bool,
};

export default EventDescriptionLegacy;
