/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";

import EventQuestions from "@untyped-shared/eventFeatures/eventQuestions";
import RootComponent from "@untyped-shared/RootComponentv4";
import useEvent from "@untyped-hooks/useEvent";

import { selectActiveEvent } from "@untyped-store/slices/eventsSlice";

function DataContainer({ eventId, jwt, overwriteState }) {
  const event = useSelector(selectActiveEvent);
  useEvent({
    eventId,
    jwt,
    overwriteState,
  });

  return <>{event && <EventQuestions forEventId={event.id} />}</>;
}

DataContainer.propTypes = {
  eventId: PropTypes.string,
  jwt: PropTypes.string,
  overwriteState: PropTypes.string,
};

function QaTool({ eventId, jwt, overwriteState, projectorView }) {
  if (projectorView) return <></>;

  return (
    <RootComponent>
      <Grid
        container
        id="questions"
        justifyContent="center"
        alignItems="center"
      >
        <DataContainer
          eventId={eventId}
          jwt={jwt}
          overwriteState={overwriteState}
        />
      </Grid>
    </RootComponent>
  );
}

QaTool.propTypes = {
  eventId: PropTypes.string,
  jwt: PropTypes.string,
  overwriteState: PropTypes.string,
  projectorView: PropTypes.bool,
};

export { DataContainer };

export default QaTool;
