/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import { EventSurvey } from "@untyped-shared/eventFeatures";

import { selectActiveEvent } from "@untyped-store/slices/eventsSlice";

import useEvent from "@untyped-hooks/useEvent";

function EventSurveyContainer({ eventId, jwt, overwriteState }) {
  const event = useSelector(selectActiveEvent);

  useEvent({
    eventId,
    jwt,
    overwriteState,
  });

  return (
    <>
      {event && !!event.activeSurvey && (
        <EventSurvey id="survey" forId={event.activeSurvey} />
      )}
    </>
  );
}

EventSurveyContainer.propTypes = {
  eventId: PropTypes.string,
  jwt: PropTypes.string,
  overwriteState: PropTypes.string,
};

export default EventSurveyContainer;
