/* eslint-disable */
// @ts-nocheck

/* eslint-enable prettier/prettier */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable import/no-unresolved */

import PropTypes from "prop-types";
import { selectActiveEvent } from "@untyped-store/slices/eventsSlice";
import { useSelector } from "react-redux";
import useEvent from "@untyped-hooks/useEvent";

function EventContainer({ eventId, children }) {
  useEvent({ eventId });
  const event = useSelector(selectActiveEvent);

  return event ? children : null;
}

EventContainer.propTypes = {
  eventId: PropTypes.string,
  children: PropTypes.node,
};

export default EventContainer;
